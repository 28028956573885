<h2 mat-dialog-title>Edit BRE</h2>
<form  [formGroup]="addBreForm" class="add-field-dialog flex-column" >
  <div class="doc-field-input-wrap">
    <label class="doc-field-input-label" title="Bre Code" for="breCode">
      BRE Code
    </label>
    <input type="text" class="doc-field-input" id="breCode"  formControlName="breCode">
  </div>
  <div *ngIf="addBreForm.controls.breCode.invalid && (addBreForm.controls.breCode.dirty || addBreForm.controls.breCode.touched)">
    <div *ngIf="addBreForm.controls.breCode.errors.required">
      <small class="text-danger">BRE Code is required</small>
    </div>
  </div>
  <div class="doc-field-input-wrap">
    <label class="doc-field-input-label" title="BRE Description" for="breDescription">
      BRE Description
    </label>
    <input type="text" class="doc-field-input" id="breDescription"  formControlName="breDescription">
  </div>
  <div *ngIf="addBreForm.controls.breDescription.invalid && (addBreForm.controls.breDescription.dirty || addBreForm.controls.breDescription.touched)">
    <div *ngIf="addBreForm.controls.breDescription.errors.required">
      <small class="text-danger">BRE Description is required</small>
    </div>
  </div>
  <div style="margin-top: 10px">
    <button class="btn btn-main" type="button" (click)="onSave()" [disabled]="!addBreForm.dirty || !addBreForm.valid">Save</button>
    <button class="btn btn-main" type="button" (click)="onCancel()">Cancel</button>
  </div>
</form>

