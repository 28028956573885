import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Bre} from '../models/Bre';
import {CustomValidator} from '../services/CustomValidator';

@Component({
  selector: 'app-add-bre',
  templateUrl: './add-bre.component.html'
})
export class AddBreComponent implements OnInit {
  addBreForm: FormGroup;
  breType: Bre;

  constructor(private dialogRef: MatDialogRef<AddBreComponent>,
              @Inject(MAT_DIALOG_DATA) data, private formBuilder: FormBuilder) {
    this.breType = data.breType ? data.breType : new Bre({
      id: null,
      breCode: '',
      breDescription: ''
    });
  }

  ngOnInit(): void {
    this.addBreForm = this.formBuilder.group({
      breCode: [this.breType.breCode, CustomValidator.required],
      breDescription: [this.breType.breDescription, CustomValidator.required]
    });
  }

  onSave(): void {
    this.breType.breCode = this.addBreForm.controls.breCode.value.trim().toUpperCase();
    this.breType.breDescription = this.addBreForm.controls.breDescription.value.trim();
    this.dialogRef.close(this.breType);
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
